import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import v from "voca";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import classnames from "classnames";
import { connect } from "react-redux";
import { changeCurrentLanguage } from "~app/actions";
import axios from "axios";
import {Icon} from "antd";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.isLanguage = this.isLanguage.bind(this);
        this.logout = this.logout.bind(this);
    }

    async logout() {
        const { history } = this.props;
        const result = await axios.post("/api/v1/auth/logout");
        if (result.status === 204) {
            history.replace("/login");
        }
    }

    changeLanguage(language) {
        this.props.changeCurrentLanguage(language);
    }

    isLanguage(language) {
        return this.props.currentLanguage === language;
    }

    componentDidMount() {
        const { i18n } = this.props;
        i18n.changeLanguage(this.props.currentLanguage);
    }

    componentDidUpdate(prevProps) {
        const { i18n } = this.props;
        if (prevProps.currentLanguage !== this.props.currentLanguage) {
            i18n.changeLanguage(this.props.currentLanguage);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <img src="/images/kbr-logo.png" width="112" height="28"/>
                        </Link>
                    </div>
                    <div className="navbar-menu is-active">
                        <div className="navbar-start">
                            <Link to="/" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("dashboard"))}</Link>
                            <Link to="/objects" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("object_plural"))}</Link>
                            <Link to="/showcases" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("showcase_plural"))}</Link>
                            <Link to="/applications" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("application_plural"))}</Link>
                            <Link to="/missions" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("mission_plural"))}</Link>
                            <Link to="/visitors" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("visitor_plural"))}</Link>
                            <Link to="/nodes" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("node_plural"))}</Link>
                            <a href="/api/v1/media/download/assets/kbr-manual-nl.pdf" target="_blank" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("manual"))}</a>
                 {/*           <Link to="/interfacetexts" className="navbar-item"
                                  activeClassName="is-active">{v.titleCase(t("interfacetext_plural"))}</Link>*/}
                        </div>
                        <div className="navbar-end">
                            <a className={classnames({
                                "navbar-item": true,
                                "is-active": this.isLanguage("fr-BE")
                            })} onClick={() => this.changeLanguage("fr-BE")} style={{ cursor: "pointer" }}>FR</a>
                            <a className={classnames({
                                "navbar-item": true,
                                "is-active": this.isLanguage("nl-BE")
                            })} onClick={() => this.changeLanguage("nl-BE")} style={{ cursor: "pointer" }}>NL</a>
                       {/*     <a className={classnames({
                                "navbar-item": true,
                                "is-active": this.isLanguage("en-US")
                            })} onClick={() => this.changeLanguage("en-US")} style={{ cursor: "pointer" }}>EN</a>*/}
                            <a className={classnames({
                                "navbar-item": true,
                            })} onClick={() => this.logout()} style={{ cursor: "pointer" }}><Icon type="logout" /></a>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        languages: state.languages,
        currentLanguage: state.currentLanguage,
    };
};

export default connect(mapStateToProps, { changeCurrentLanguage })(withRouter(withTranslation()(Navigation)));
