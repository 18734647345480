import React, { Component } from "react";
import axios from "axios";
import Application from "~app/models/application";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Button, Form, Icon, Input, Select, Tabs, Upload } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import { withRouter } from "react-router";
import TableTransfer from "~app/components/tabletransfer";
import compose from "lodash/fp/compose";
import WithMediaLinks from "~app/hoc/medialinks";
import Imagable from "~app/hoc/imagable";
import update from "immutability-helper";

const { Option } = Select;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const leftShowcaseColumns = (t, translation) => [
    {
        title: v.titleCase(t("name_internal")),
        key: "internal",
        render: showcase => showcase.internal,
    },
    {
        title: v.titleCase(t("application")),
        key: "application",
        render: showcase => (
            showcase.application && showcase.application.internal || "-"),
    },
];
const rightShowcaseColumns = (t, translation) => [
    {
        title: v.titleCase(t("name_internal")),
        key: "internal",
        render: showcase => {
            return <Link to={"/showcases/" + showcase.id}>{showcase.internal}</Link>;
        },
    },
    {
        title: v.titleCase(t("published")),
        key: "published",
        render: showcase => {
            return showcase.published && <Icon type="eye"/> || <Icon type="eye-invisible"/>;
        },
    },
];

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            application: null,
            showcases: [],
            missions: [],
        };
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onMissionIdChange = this.onMissionIdChange.bind(this);
        this.onShowcaseIdsChange = this.onShowcaseIdsChange.bind(this);
        this.setMediaLinks = this.setMediaLinks.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    async componentDidMount() {
        this.setState({
            application: await this.getApplication(),
            showcases: await this.getShowcases(),
            missions: await this.getMissions(),
        });
        this.props.syncLanguages();
    }

    onShowcaseIdsChange(ids) {
        const { application } = this.state;
        application.showcaseIds = ids;
        this.setState({ application });
    }

    setMediaLinks(mediaLinks) {
        const newState = update(this.state, {
            application: {
                mediaLinks:
                    { $set: mediaLinks },
            },
        });
        this.setState(newState);
    }

    async getApplication() {
        let application = this.props.location.state && this.props.location.state.application || null;
        if (application !== null) {
            return new Application(application);
        }
        const applicationId = this.props.match.params.id;
        if (applicationId) {
            const result = await axios.get(`/api/v1/applications/${applicationId}`);
            return new Application(result.data);
        }
        application = new Application();
        return application;
    }

    async getShowcases() {
        const result = await axios.get("/api/v1/showcases");
        return result.data;
    }

    async getMissions() {
        const result = await axios.get("/api/v1/missions");
        return result.data;
    }

    async save() {
        const { form, goBack } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const application = { ...this.state.application };
                application.internal = values.internal;
                const data = {
                    application,
                };
                await axios.post(application.id ? `/api/v1/applications/${application.id}` : "/api/v1/applications", data);
                goBack();
            }
        });
    }

    onMissionIdChange(id) {
        const { application } = this.state;
        application.missionId = id;
        this.setState({ application });
    }

    render() {
        const { t, form, translation } = this.props;
        const { application, showcases, missions } = this.state;
        const { getFieldDecorator } = form;
        const getFilesFromMediaLinks = this.props.getFilesFromMediaLinks.bind(this);
        const syncFilesToMediaLinks = this.props.syncFilesToMediaLinks.bind(this);
        return (
            application
            && (
                <div>
                    <Form {...formItemLayout}>
                        <h1 className="title">{application.internal || "-"}</h1>
                        <Form.Item label={v.titleCase(t("name_internal"))}>
                            {getFieldDecorator("internal", {
                                initialValue: application.internal,
                                rules: [
                                    {
                                        required: true,
                                        message: v.capitalize(t("error_required")),
                                    },
                                ],
                            })(
                                <Input placeholder={v.titleCase(t("name_internal"))}/>
                            )}
                        </Form.Item>
                        <div className="mt20">
                            <div className="field">
                                <label className="label">{v.titleCase(t("showcase_plural"))}</label>
                                {application.showcaseIds &&
                                <TableTransfer
                                    dataSource={showcases}
                                    targetKeys={application.showcaseIds}
                                    onChange={this.onShowcaseIdsChange}
                                    leftColumns={leftShowcaseColumns(t, translation)}
                                    rightColumns={rightShowcaseColumns(t, translation)}
                                    rowKey={showcase => showcase.id}
                                    titles={[v.titleCase(t("showcase_unselected_plural")), v.titleCase(t("showcase_selected_plural"))]}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return translation(option.name)?.toLowerCase()
                                            .includes(input.toLowerCase()) || option.internal && option.internal.toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                />}
                            </div>
                        </div>
                        <div className="mt20">
                            <div className="field">
                                <label className="label">{v.titleCase(t("mission"))}</label>
                                <Select defaultValue={application.missionId} style={{ width: "100%" }} onChange={e => this.onMissionIdChange(e)}>
                                    {missions.map(mission => (
                                        <Option title={mission.internal} key={mission.id} value={mission.id}>{mission.internal}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="mt20">
                            <div className="field">
                                <label className="label">{v.titleCase(t("screensaver"))}</label>
                                <Upload listType="picture-card" action="/api/v1/media/upload"
                                        onChange={e => this.setMediaLinks(syncFilesToMediaLinks(application.mediaLinks, e.fileList, "screensaver"))}
                                        defaultFileList={getFilesFromMediaLinks(application.mediaLinks, "screensaver")}>
                                    {getFilesFromMediaLinks(application.mediaLinks, "screensaver").length < 1 && <Button>
                                        <Icon type="plus"/> Upload
                                    </Button>}
                                </Upload>
                            </div>
                        </div>
                        <div className="field is-grouped mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={this.goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            )
        );
    }
}

export default compose(WithMediaLinks, Imagable, Translatable, withRouter, Form.create({ name: "edit" }))(Edit);
