import _ from "lodash";

export default class Object {
    constructor(model) {
        this.setDefault();
        this.setModel(model);
    }

    setDefault() {
        this.id = null;
        this.internal = null;
        this.title = {};
        this.extendedTitle = {};
        this.descriptions = {
            explorer: {},
            academic: {},
            extra: {},
        };
        this.mediaLinks = [];
        this.isTop = false;
        this.published = true;
        this.showcaseId = null;
        this.showcase = null;
        this.position = null;
    }

    setModel(model) {
        if (model) {
            this.id = model.id;
            this.internal = model.internal;
            this.title = model.title;
            this.extendedTitle = model.extendedTitle;
            this.descriptions = _.defaults(model.descriptions, this.descriptions);
            this.mediaLinks = model.mediaLinks;
            this.isTop = model.isTop;
            this.published = model.published;
            this.showcaseId = model.showcaseId;
            this.showcase = model.showcase;
            this.startPublishingDate = model.startPublishingDate;
            this.endPublishingDate = model.endPublishingDate;
            this.position = model.position;
        }
    }
}
