import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import v from "voca";
import Showcase from "~app/models/showcase";
import { Button, Icon, Input, Popconfirm, Table } from "antd";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const ButtonGroup = Button.Group;
const { Search } = Input;
const { Column } = Table;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showcases: [],
        };
        this.deleteShowcase = this.deleteShowcase.bind(this);
    }

    async componentDidMount() {
        this.setState({
            showcases: await Index.getShowcases(),
        });
    }

    static async getShowcases(search) {
        const params = {};
        params.search = search;
        const result = await axios.get("/api/v1/showcases", { params });
        return result.data.map(r => new Showcase(r));
    }

    async deleteShowcase(showcase) {
        await axios.delete("/api/v1/showcases/" + showcase.id);
        this.setState({
            showcases: await Index.getShowcases(),
        });
    }

    async search(search) {
        this.setState({
            showcases: await Index.getShowcases(search),
        });
    }

    render() {
        const { t, translation } = this.props;
        const { showcases } = this.state;
        return (
            <>
                <Search placeholder={v.capitalize(t("showcase_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={showcases} rowKey={showcase => showcase.id}  className="mt20">
                    <Column key="internal" title={v.titleCase(t("name_internal"))} render={showcase => showcase.internal || "-"}/>
                    <Column key="identifier" title={v.titleCase(t("identifier"))} render={showcase => showcase.identifier || "-"}/>
                    <Column key="title" title={v.titleCase(t("title"))} render={showcase => translation(showcase.name)}/>
                    <Column key="application" title={v.titleCase(t("application"))} render={showcase => <>{showcase.application ? showcase.application.internal : "-"}</>}/>
                    <Column key="actions" className="buttons-group" title={v.titleCase(t("action_plural"))} render={showcase => <>
                        <Link to={{pathname: `/showcases/${showcase.id}`, state: {
                                showcase,
                            },
                        }}>
                            <Button icon="edit"/>
                        </Link>
                        <Popconfirm title="Vitrine verwijderen?" icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteShowcase(showcase)}>
                            <Button icon="delete"/>
                        </Popconfirm>
                    </>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/showcases/create",
                    }}
                >
                    <Button icon="plus">{v.titleCase(t("showcase_new"))} </Button>
                </Link>
            </>
        );
    }
}

export default compose(Translatable)(Index);
