import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Applications from "./views/applications/base";
import Media from "./views/media/base";
import Showcases from "./views/showcases/base";
import Objects from "./views/objects/base";
import Missions from "./views/missions/base";
import Visitors from "./views/visitors/base";
import Dashboard from "./views/dashboard/base";
import Nodes from "./views/nodes/base";
import InterfaceTexts from "./views/interfacetexts/base";
import Navigation from "~app/components/navigation";
import "~app/styles/index.scss";
import "~bulma-extensions";
import "~fontawesome";
import "~app/translations";
import { withTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { persistor, store } from "~app/store";
import { PersistGate } from "redux-persist/integration/react";
import { BackTop, Spin } from "antd";
import Login from "~app/views/login";
import { CookiesProvider, withCookies } from "react-cookie";
import PrivateRoute from "~app/components/private-route";

class App extends Component {
    render() {
        return (
            <CookiesProvider>
                <Provider store={store}>
                    <PersistGate loading={<Spin/>} persistor={persistor}>
                        <BackTop/>
                        <Router>
                            <Switch>
                                <Route path="/login" render={() => (<Login cookies={this.props.cookies} />)} />
                                <Route path="/" render={() => <div className="background">
                                    <Navigation/>
                                    <section className="section">
                                        <div className="container">
                                            <Switch>
                                                <PrivateRoute exact path="/" component={Dashboard} />
                                                <PrivateRoute path="/applications" component={Applications} />
                                                <PrivateRoute path="/showcases" component={Showcases} />
                                                <PrivateRoute path="/objects" component={Objects} />
                                                <PrivateRoute path="/missions" component={Missions} />
                                                <PrivateRoute path="/media" component={Media} />
                                                <PrivateRoute path="/interfacetexts" component={InterfaceTexts} />
                                                <PrivateRoute path="/visitors" component={Visitors} />
                                                <PrivateRoute path="/nodes" component={Nodes} />
                                            </Switch>
                                        </div>
                                    </section>
                                    <footer className="footer">
                                        <div className="content has-text-centered">
                                            <p>
                                                <strong>CREATE.eu</strong> &copy; 2020
                                            </p>
                                        </div>
                                    </footer>
                                </div>}/>
                            </Switch>
                        </Router>
                    </PersistGate>
                </Provider>
            </CookiesProvider>
        );
    }
}

export default withCookies(withTranslation()(App));
