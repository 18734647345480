import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import Showcase from "~app/models/showcase";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Table, Tag, Transfer } from "antd";
import difference from "lodash/difference";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === "left" ? leftColumns : rightColumns;

            const rowSelection = {
                getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? "none" : null }}
                />
            );
        }}
    </Transfer>
);

const leftTableColumns = [
    {
        dataIndex: "title",
        title: "Name",
    },
    {
        dataIndex: "tag",
        title: "Tag",
        render: tag => <Tag>{tag}</Tag>,
    },
    {
        dataIndex: "description",
        title: "Description",
    },
];
const rightTableColumns = [
    {
        dataIndex: "title",
        title: "Name",
        render: (text, object) => <Link to={"/objects/" + object.key}>{text}</Link>,
    },
];

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showcase: null,
            objects: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.setChange = this.setChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    onChange(ids) {
        const { showcase } = this.state;
        showcase.objectIds = ids;
        this.setState({ showcase });
    }

    async componentDidMount() {
        this.setState({
            showcase: await this.getShowcase(),
            objects: await this.getObjects(),
        });
    }

    handleChange(event) {
        const { target } = event;
        const { showcase } = this.state;
        showcase[target.name] = target.value;
        this.setState({
            showcase,
        });
    }

    async getShowcase() {
        let showcase = this.props.location.state && this.props.location.state.showcase || null;
        if (showcase !== null) {
            return new Showcase(showcase);
        }
        const showcaseId = this.props.match.params.id;
        if (showcaseId) {
            const result = await axios.get(`/api/v1/showcases/${showcaseId}`);
            return new Showcase(result.data);
        }
        showcase = new Showcase();
        return showcase;
    }

    async getObjects() {
        const result = await axios.get("/api/v1/showcases/objects");
        return result.data.map(object => {
            return {
                key: object.id,
                title: object.title["nl-BE"],
            };
        });
    }

    async save() {
        const { showcase } = this.state;
        const data = {
            showcase,
        };
        console.log(data);
        await axios.post(showcase.id ? `/api/v1/showcases/${showcase.id}` : "/api/v1/showcases", data);
        this.goBack();
    }

    setChange(val) {
        const { showcase } = this.state;
        showcase.name = val;
        this.setState(showcase);
    }

    render() {
        const { t, translation } = this.props;
        const { showcase, objects } = this.state;
        return (
            showcase
            && (
                <div>
                    <h1 className="title">{translation(showcase.name)}</h1>
                    <div className="mt20">
                        {showcase.objects &&
                        <TableTransfer
                            dataSource={objects}
                            targetKeys={showcase.objectIds}
                            onChange={this.onChange}
                            leftColumns={leftTableColumns}
                            rightColumns={rightTableColumns}
                        />
                        }
                    </div>
                    <div className="field is-grouped mt20">
                        <div className="control">
                            <button className="button is-success" onClick={this.save}>{t("save")}</button>
                        </div>
                        <div className="control">
                            <button className="button" onClick={this.goBack}>{t("cancel")}</button>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default compose(Translatable)(Edit);
