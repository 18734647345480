import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./index";
import Edit from "./edit";

export default class Base extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/applications" component={Index}/>
                <Route path="/applications/create" component={Edit}/>
                <Route path="/applications/:id" component={Edit}/>
            </Switch>
        );
    }
}
