import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import Index from "./index";
import Edit from "./edit";

export default class Base extends Component {
    render() {
        return (
                <Switch>
                    <Route exact path="/media" component={Index}/>
                    <Route path="/media/create" component={Edit}/>
                    <Route path="/media/:id" component={Edit}/>
                </Switch>
        )
    }
}
