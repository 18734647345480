import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { syncLanguages } from "~app/actions";
import i18next from "i18next";

const defaultKey = "fr-BE";

const translation = function (translatable, languageKey = i18next.language, returnDefault = true) {
    if (translatable[languageKey]) {
        return translatable[languageKey];
    }
    if (returnDefault) {
        return translatable[defaultKey];
    }
    return null;
};

const mapStateToProps = state => {
    return {
        languages: state.languages,
        currentLanguage: state.currentLanguage,
    };
};

const onChange = function onChange(objectName, key, value, language) {
    const { [objectName]: object } = this.state;
    if (!Array.isArray(key)) {
        if (language) {
            object[key][language] = value;
        } else {
            object[key] = value;
        }
        this.setState({
            [objectName]: object,
        });
        return;
    }
    const property = object[key[0]];
    if (language) {
        property[key[1]][language] = value;
    } else {
        property[key[1]] = value;
    }
    this.setState({
        [objectName]: object,
    });
};


function Translatable(WrappedComponent) {
    const WrapperComponent = class extends Component {
        async componentDidMount() {
            this.props.syncLanguages();
            this.goBack = this.goBack.bind(this);
        }

        goBack() {
            this.props.history.goBack();
        };

        render() {
            return <WrappedComponent currentTranslation={translation} translation={translation} onChange={onChange} goBack={this.goBack} {...this.props} />;
        }
    };
    return connect(mapStateToProps, {
        syncLanguages,
    })(withTranslation()(WrapperComponent));
}

export default Translatable;
