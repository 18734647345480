import Answer from "./answer";

export default class Object {
    constructor(model) {
        this.id = (model && model.id) || null;
        this.internal = (model && model.internal) || null;
        this.question = (model && model.question) || {};
        this.answers = (model && model.answers && model.answers.map(answer => new Answer(answer))) || [];
        this.feedbackGood = (model && model.feedbackGood) || {};
        this.feedbackBad = (model && model.feedbackBad) || {};
        this.feedbackTitle = (model && model.feedbackTitle) || {};
        this.feedbackText = (model && model.feedbackText) || {};
        this.mediaLinks = (model && model.mediaLinks) || [];
        this.applications = (model && model.applications) || [];
    }
}
