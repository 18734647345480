import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./index";

export default class Base extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/visitors" component={Index} />
            </Switch>
        );
    }
}
