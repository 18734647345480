import React, { Component } from "react";
import axios from "axios";
import Mission from "~app/models/mission";
import "react-bulma-components/dist/react-bulma-components.min.css";
import "antd/dist/antd.css";
import { Button, Form, Icon, Input, Modal, Switch, Table, Tabs, Upload } from "antd";
import v from "voca";
import TextEditor from "~app/components/text-editor";
import compose from "lodash/fp/compose";
import { withRouter } from "react-router";
import WithMediaLinks from "~app/hoc/medialinks";
import Imagable from "~app/hoc/imagable";
import Translatable from "~app/hoc/translatable";
import update from "immutability-helper";
import Answer from "~app/models/answer";

const { TextArea } = Input;
const { TabPane } = Tabs;

const { Column } = Table;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mission: null,
            isAnswerModalVisible: false,
            answerIndex: null,
        };
        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showAnswerModal = this.showAnswerModal.bind(this);
        this.saveAnswer = this.saveAnswer.bind(this);
        this.cancelAnswer = this.cancelAnswer.bind(this);
        this.newAnswer = this.newAnswer.bind(this);
        this.deleteAnswer = this.deleteAnswer.bind(this);
        this.setMediaLinks = this.setMediaLinks.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    async componentDidMount() {
        this.setState({
            mission: await this.getMission(),
        });
    }

    async getMission() {
        let mission =
            (this.props.location.state && this.props.location.state.mission) || null;
        if (mission !== null) {
            return new Mission(mission);
        }
        const missionId = this.props.match.params.id;
        if (missionId) {
            const result = await axios.get(`/api/v1/missions/${missionId}`);
            return new Mission(result.data);
        }
        mission = new Mission();
        return mission;
    }

    async save() {
        const { mission } = this.state;
        const data = {
            mission: mission,
        };
        await axios.post(
            mission.id ? `/api/v1/missions/${mission.id}` : "/api/v1/missions",
            data
        );
        this.goBack();
    }

    onChange(property, value, language) {
        const { mission } = this.state;
        let m = mission;
        if (Array.isArray(property)) {
            m = mission[property[0]];
        }
        if (language) {
            m[Array.isArray(property) ? property[1] : property][language] = value;
        } else {
            m[Array.isArray(property) ? property[1] : property] = value;
        }
        this.setState({
            mission,
        });
    }

    showAnswerModal(answer) {
        this.setState({
            isAnswerModalVisible: true,
            answerIndex: this.state.mission.answers.indexOf(answer),
        });
    }

    saveAnswer() {
        this.setState({
            isAnswerModalVisible: false,
            answerIndex: null,
        });
    }

    handleAnswer(index, key, value, language) {
        const { mission } = this.state;
        if (language) {
            mission.answers[index][key][language] = value;
        } else {
            mission.answers[index][key] = value;
        }
        this.setState({ mission });
    }

    cancelAnswer() {
        this.setState({
            isAnswerModalVisible: false,
            answerIndex: null
        });
    }

    deleteAnswer(value) {
        const { mission } = this.state;
        mission.answers = mission.answers.filter(answer => answer !== value);
        this.setState({ mission });
    }

    newAnswer() {
        const { mission } = this.state;
        mission.answers.push(new Answer());
        this.setState({ mission });
        this.showAnswerModal(mission.answers[mission.answers.length - 1]);
    }

    setMediaLinks(mediaLinks) {
        const newState = update(this.state, {
            mission: {
                mediaLinks:
                    { $set: mediaLinks },
            },
        });
        this.setState(newState);
    }


    render() {
        const {
            t,
            form,
            languages,
            currentLanguage,
            translation,
        } = this.props;
        const { getFieldDecorator } = form;
        const { mission, isAnswerModalVisible, answerIndex } = this.state;
        const getFilesFromMediaLinks = this.props.getFilesFromMediaLinks.bind(this);
        const syncFilesToMediaLinks = this.props.syncFilesToMediaLinks.bind(this);
        return (
            <>{mission &&
            <Modal
                title={v.titleCase(t("answer_edit"))}
                visible={isAnswerModalVisible}
                onOk={this.saveAnswer}
                onCancel={this.cancelAnswer}
            >
                {answerIndex !== null && (
                    <div>
                        <Tabs defaultActiveKey={currentLanguage} animated={false}>
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Form.Item label={v.titleCase(t("answer"))}>
                                        <Input
                                            defaultValue={
                                                translation(mission.answers[answerIndex].title, language.key, false)
                                            }
                                            onChange={e =>
                                                this.handleAnswer(
                                                    answerIndex,
                                                    "title",
                                                    e.target.value,
                                                    language.key
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("correct"))}>
                                        <Switch
                                            defaultChecked={
                                                mission.answers[answerIndex].isCorrect
                                            }
                                            onChange={value =>
                                                this.handleAnswer(answerIndex, "isCorrect", value)
                                            }
                                        />
                                    </Form.Item>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                )}
            </Modal>
            }
                {mission && (
                    <Form {...formItemLayout}>
                        <Form.Item label={v.titleCase(t("name_internal"))}>
                            <Input
                                placeholder={v.titleCase(t("name_internal"))}
                                defaultValue={mission.internal}
                                onChange={e =>
                                    this.onChange(
                                        "internal",
                                        e.target.value,
                                    )
                                }
                            />
                        </Form.Item>
                        <Tabs defaultActiveKey={currentLanguage} animated={false}>
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Form.Item label={v.titleCase(t("question"))}>
                                        <Input
                                            placeholder={v.titleCase(t("question"))}
                                            defaultValue={translation(mission.question, language.key, false)}
                                            onChange={e =>
                                                this.onChange(
                                                    "question",
                                                    e.target.value,
                                                    language.key,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("answer_plural"))}>
                                        <Table
                                            dataSource={mission.answers}
                                            rowKey={answer => answer.id}
                                        >
                                            <Column
                                                key="answer"
                                                title={v.titleCase(t("answer"))}
                                                render={answer => translation(answer.title, language.key, false)}
                                            />
                                            <Column
                                                key="correct"
                                                title={v.titleCase(t("correct"))}
                                                render={answer =>
                                                    (answer.isCorrect && (
                                                        <Icon type="check-circle"/>
                                                    )) || <Icon type="close-circle"/>
                                                }
                                            />
                                            <Column
                                                key="actions"
                                                title={v.titleCase(t("action_plural"))}
                                                render={answer => (
                                                    <>
                                                        <Button
                                                            onClick={() => this.showAnswerModal(answer)}
                                                            icon="edit"
                                                        ></Button>{" "}
                                                        <Button
                                                            onClick={() => this.deleteAnswer(answer)}
                                                            icon="delete"
                                                        ></Button>
                                                    </>
                                                )}
                                            />
                                        </Table>
                                        <Button onClick={this.newAnswer}>
                                            {v.titleCase(t("answer_new"))}
                                        </Button>
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("feedback_title"))}>
                                        <Input
                                            defaultValue={
                                                this.state.mission.feedbackTitle[
                                                    language.key
                                                    ]
                                            }
                                            onChange={e =>
                                                this.onChange(
                                                    "feedbackTitle",
                                                    e.target.value,
                                                    language.key,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("feedback_text"))}>
                                        <TextEditor
                                            defaultValue={
                                                translation(this.state.mission.feedbackText, language.key, false)
                                            }
                                            onChange={value =>
                                                this.onChange(
                                                    "feedbackText",
                                                    value,
                                                    language.key,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("feedback_good"))}>
                                        <TextEditor
                                            defaultValue={
                                                translation(this.state.mission.feedbackGood, language.key, false)
                                            }
                                            onChange={value =>
                                                this.onChange(
                                                    "feedbackGood",
                                                    value,
                                                    language.key,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("feedback_bad"))}>
                                        <TextEditor
                                            defaultValue={
                                                translation(this.state.mission.feedbackBad, language.key, false)
                                            }
                                            onChange={value =>
                                                this.onChange(
                                                    "feedbackBad",
                                                    value,
                                                    language.key,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("image_question"))} extra={v.capitalize(t("image_question_extra"))}>
                                        <Upload
                                            listType="picture-card"
                                            action="/api/v1/media/upload"
                                            onChange={e =>
                                                this.setMediaLinks(syncFilesToMediaLinks(
                                                    mission.mediaLinks,
                                                    e.fileList,
                                                    "question",
                                                ))
                                            }
                                            defaultFileList={getFilesFromMediaLinks(
                                                mission.mediaLinks,
                                                "question",
                                            )}
                                        >{getFilesFromMediaLinks(
                                            mission.mediaLinks,
                                            "question",
                                        ).length < 1 &&
                                        <Button>
                                            <Icon type="plus"/> {v.titleCase(t("upload"))}
                                        </Button>}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item label={v.titleCase(t("image_feedback"))} extra={v.capitalize(t("image_feedback_extra"))}>
                                        <Upload
                                            listType="picture-card"
                                            action="/api/v1/media/upload"
                                            onChange={e =>
                                                this.setMediaLinks(syncFilesToMediaLinks(
                                                    mission.mediaLinks,
                                                    e.fileList,
                                                    "feedback",
                                                ))
                                            }
                                            defaultFileList={getFilesFromMediaLinks(
                                                mission.mediaLinks,
                                                "feedback",
                                            )}
                                        >{getFilesFromMediaLinks(
                                            mission.mediaLinks,
                                            "feedback",
                                        ).length < 1 &&
                                        <Button>
                                            <Icon type="plus"/> {v.titleCase(t("upload"))}
                                        </Button>}
                                        </Upload>
                                    </Form.Item>
                                </TabPane>
                            ))}
                        </Tabs>
                        <div className="field is-grouped mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>
                                    {v.titleCase(t("save"))}
                                </button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={this.goBack}>
                                    {v.titleCase(t("cancel"))}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </>
        );
    }
}

export default compose(
    WithMediaLinks,
    Imagable,
    Translatable,
    withRouter,
    Form.create({ name: "edit" }),
)(Edit);
