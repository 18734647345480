const STORE_APPLICATIONS = "STORE_APPLICATIONS";
const STORE_SHOWCASES = "STORE_SHOWCASES"
const STORE_LANGUAGES = "STORE_LANGUAGES";
const CHANGE_CURRENT_LANGUAGE = "CHANGE_CURRENT_LANGUAGE";

class Actions {
    static get STORE_APPLICATIONS() {
        return STORE_APPLICATIONS;
    }

    static get STORE_SHOWCASES() {
        return STORE_SHOWCASES;
    }

    static get STORE_LANGUAGES() {
        return STORE_LANGUAGES;
    }

    static get CHANGE_CURRENT_LANGUAGE() {
        return CHANGE_CURRENT_LANGUAGE;
    }
}

export default Actions;
