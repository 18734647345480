import React, { Component } from "react";
import axios from "axios";
import v from "voca";
import Visitor from "~app/models/visitor";
import { Button, Input, message, Table } from "antd";
import _ from "lodash";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const { Column } = Table;
const { Search } = Input;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitors: [],
        };
        this.search = _.debounce(this.search.bind(this), 200);
        this.exit = this.exit.bind(this);
    }

    async componentDidMount() {
        this.setState({
            visitors: await Index.getVisitors(),
        });
    }

    static async getVisitors(search) {
        const params = {};
        params.search = search;
        const result = await axios.get("/api/v1/visitors/wearable", { params });
        return result.data.map(r => new Visitor(r));
    }

    async search(search) {
        this.setState({
            visitors: await Index.getVisitors(search),
        });
    }

    async exit(visitor) {
        try {
            await axios.post(`/api/v1/visitors/wearable/${visitor.wearableKey}/exit`);
            message.info("Visitor Exited");
            this.setState({
                visitors: await Index.getVisitors(),
            });
        } catch (err) {
            message.error("Visitor Not Exited");
        }
    }

    render() {
        const { t, translation } = this.props;
        const { visitors } = this.state;
        return (
            <>
                <Search placeholder={v.capitalize(t("visitor_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={visitors} rowKey={visitor => visitor.id} className="mt20">
                    <Column key="wearable" title={v.titleCase(t("wearable"))} render={visitor => visitor.wearableKey}/>
                    <Column key="actions" title={v.titleCase(t("action_plural"))} render={visitor =>
                        <>
                            <Button icon="logout" onClick={() => this.exit(visitor)} disabled={visitor.wearableKey === null}/>
                        </>}
                    />
                </Table>
            </>
        );
    }
}

export default compose(Translatable)(Index);
