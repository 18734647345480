import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import Index from "./index";

export default class Base extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/nodes" component={Index}/>
            </Switch>
        )
    }
}