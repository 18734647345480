export default class Showcase {
    constructor(model) {
        this.id = (model && model.id) || null;
        this.internal = (model && model.internal) || null;
        this.identifier = (model && model.identifier) || null;
        this.name = (model && model.name) || {};
        this.description = (model && model.description) || {};
        this.objects = (model && model.objects) || [];
        this.objectIds = (this.objects && this.objects.map(object => object.id)) || [];
        this.application = (model && model.application) || null;
        this.published = (model && model.published) || false;
    }
}
