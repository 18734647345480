import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import v from "voca";
import Application from "~app/models/application";
import { Button, Input, Table } from "antd";
import _ from "lodash";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const { Column } = Table;
const { Search } = Input;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
        };
        this.search = _.debounce(this.search.bind(this), 200);
    }

    async componentDidMount() {
        this.setState({
            applications: await Index.getApplications(),
        });
    }

    static async getApplications(search) {
        const params = {};
        params.search = search;
        const result = await axios.get("/api/v1/applications", { params });
        return result.data.map(r => new Application(r));
    }

    async search(search) {
        this.setState({
            applications: await Index.getApplications(search),
        });
    }

    render() {
        const { t, translation } = this.props;
        const { applications } = this.state;
        return (
            <>
                <Search placeholder={v.capitalize(t("application_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={applications} rowKey={application => application.id} className="mt20">
                    <Column key="title" title={v.titleCase(t("application"))} render={application => application.internal}/>
                    <Column key="showcases" title={v.titleCase(t("showcase_count"))} render={application => <>{application.showcaseIds.length}</>}/>
                    <Column key="actions" title={v.titleCase(t("action_plural"))} render={application =>
                        <>
                            <Link to={{
                                pathname: `/applications/${application.id}`,
                                state: {
                                    application,
                                },
                            }}>
                                <Button icon="edit"/>
                            </Link>
                        </>}
                    />
                </Table>
                <Link
                    to={{
                        pathname: "/applications/create",
                    }}
                >
                    <Button icon="plus">{v.titleCase(t("application_new"))} </Button>
                </Link>
            </>
        );
    }
}

export default compose(Translatable)(Index);
