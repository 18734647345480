import React, { Component } from "react";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import Mission from "~app/models/mission";
import { Button, Icon, Popconfirm, Table, Input } from "antd";
import { Link } from "react-router-dom";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import compose from "lodash/fp/compose";

const { Column } = Table;
const { Search } = Input;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            missions: [],
        };
    }

    async componentDidMount() {
        this.setState({
            missions: await this.constructor.getMissions(),
        });
    }

    static async getMissions(search) {
        const params = {};
        params.search = search;
        const result = await axios.get("/api/v1/missions", { params });
        return result.data.map(r => new Mission(r));
    }

    async deleteMission(object) {
        await axios.delete("/api/v1/missions/" + object.id);
        this.setState({
            missions: await this.constructor.getMissions(),
        });
    }

    async search(search) {
        this.setState({
            missions:  await this.constructor.getMissions(search),
        });
    }

    render() {
        const { t, currentTranslation } = this.props;
        const { missions } = this.state;
        return (
            <>
                <Search placeholder={v.capitalize(t("mission_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={missions} rowKey={medium => medium.id}>
                    <Column
                        title={v.titleCase(t("name_internal"))}
                        key="internal"
                        render={mission => mission.internal}/>
                    <Column
                        title={v.titleCase(t("application_plural"))}
                        key="applications"
                        render={mission => (
                            mission.applications && mission.applications.map(application => application.internal)
                                .join(", ") || "-"
                        )}/>
                    <Column
                        title={v.titleCase(t("question"))}
                        key="question"
                        render={mission => (
                            currentTranslation(mission.question) || "-"
                        )}/>
                    <Column
                        title={v.titleCase(t("action_plural"))}
                        key="actions"
                        render={mission => (
                            <>
                                <Link to={`/missions/${mission.id}`}><Button icon="edit"/></Link>&nbsp;&nbsp;
                                <Popconfirm title={v.titleCase(t("mission_delete"))} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteMission(mission)}
                                ><Button icon="delete"/>
                                </Popconfirm>
                            </>
                        )}/>
                </Table>
                <Link
                    to={{
                        pathname: "/missions/create",
                    }}
                >
                    <Button icon="plus">{v.titleCase(t("mission_new"))} </Button>
                </Link>
            </>
        );
    }
}

export default compose(Translatable)(Index);
