import Actions from "~app/constants/actions";

const initialState = {
    applications: [],
    showcases: [],
    languages: [],
    currentLanguage: "fr-BE",
};

function reducer(state = initialState, action) {
    switch (action.type) {
    case Actions.STORE_APPLICATIONS: {
        return {
            ...state,
            applications: action.payload,
        };
    }
    case Actions.STORE_SHOWCASES: {
        return {
            ...state,
            showcases: action.payload,
        };
    }
    case Actions.CHANGE_CURRENT_LANGUAGE: {
        return {
            ...state,
            currentLanguage: action.payload,
        };
    }
    case Actions.STORE_LANGUAGES: {
        return {
            ...state,
            languages: action.payload,
        };
    }
    default: {
        return state;
    }
    }
}

export default reducer;
