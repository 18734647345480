import React, { Component } from "react";
import axios from "axios";
import { Avatar, Table } from "antd";
import compose from "lodash/fp/compose";
import Imagable from "~app/hoc/imagable";
import Translatable from "~app/hoc/translatable";

const { Column } = Table;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            media: [],
        };
    }

    componentDidMount() {
        this.update();
    }

    update() {
        axios.get("/api/v1/media")
            .then(result => {
                this.setState({
                    media: result.data,
                });
            });
    }

    render() {
        const { resizeImage } = this.props;
        const { media } = this.state;
        return (
            <Table dataSource={media} rowKey={medium => medium.id}>
                <Column title="Preview" key="preview" render={(text, medium) => (
                    <Avatar size="large" src={resizeImage(`/api/v1/media/download/${medium.uri}`, 48, 48)} />
                )}/>
                <Column title="URI" dataIndex="uri"/>
            </Table>
        );
    }
}

export default compose(Imagable, Translatable)(Index);
