import React, { Component } from "react";
import { Button, Col, Form, Icon, Input, Row, message } from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";

class Login extends Component {
    onSubmit = e => {
        e.preventDefault();
        const { form, history } = this.props;
        form.validateFields(async (err, values) => {
                if (!err) {
                    try {
                        const data = {
                            username: values.username,
                        password: values.password,
                        };
                        const result = await axios.post("/api/v1/auth/login", data);
                        if (result.status === 200) {
                            history.replace("/");
                        }
                    } catch (error) {
                        if (error.response?.status === 401 || error.response?.status === 403) {
                            message.error("Authentication Failed");
                        } else {
                            message.error("An Error Occurred");
                        }
                    }
                }
            }
        );
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Row type="flex" justify="center" align="middle" style={{ minHeight: "100%" }}>
                <Col span={4}>
                    <Form onSubmit={this.onSubmit} className="login-form">
                        <Form.Item>
                            <img src="/images/kbr-logo-large-black.png"/>
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("username", {
                                rules: [{
                                    required: true,
                                    message: "Please input your username.",
                                }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }}/>}
                                    placeholder="Username"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("password", {
                                rules: [{
                                    required: true,
                                    message: "Please input your password.",
                                }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }}/>}
                                    type="password"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const WrappedLogin = Form.create({ name: "login" })(Login);

export default withRouter(WrappedLogin);
