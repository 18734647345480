import React, { Component } from "react";

function getFilesFromMediaLinks(mediaLinks, group) {
    const { getImage, resizeImage } = this.props;
    return mediaLinks.filter(mediaLink => mediaLink.group === group)
        .map(mediaLink => ({
            uid: mediaLink.uri,
            name: mediaLink.uri,
            status: "done",
            url: getImage(mediaLink.uri),
            thumbUrl: resizeImage(mediaLink.uri, 86, 86),
        }));
}

function syncFilesToMediaLinks(mediaLinks, files, group) {
    const newMediaLinks = mediaLinks.filter(mediaLink => mediaLink.group !== group);
    files.forEach(file => {
        if (file.status === "done") {
            if (file.response) {
                newMediaLinks.push({
                    uri: file.response.uri,
                    group,
                });
            } else {
                newMediaLinks.push({
                    uri: file.url,
                    group,
                });
            }
        }
    });
    return newMediaLinks;
}

function WithMediaLinks(WrappedComponent) {
    return class extends Component {
        render() {
            return <WrappedComponent getFilesFromMediaLinks={getFilesFromMediaLinks} syncFilesToMediaLinks={syncFilesToMediaLinks} {...this.props} />;
        }
    };
}

export default WithMediaLinks;
