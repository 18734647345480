import React, { Component } from "react";
import Translatable from "~app/hoc/translatable";
import { Col, DatePicker, Empty, Form, Row, Typography } from "antd";
import compose from "lodash/fp/compose";
import { syncLanguages } from "~app/actions";
import { connect } from "react-redux";
import axios from "axios";
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment";
import v from "voca";


const { Title } = Typography;
const { RangePicker } = DatePicker;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileData: [],
            langaugeData: [],
            profiles: [],
            languages: [],
            visitors: [],
            dateRange: [moment()
                .subtract(30, "days"), moment()],
        };
        this.update = this.update.bind(this);
        this.renderLanguageLabel = this.renderLanguageLabel.bind(this);
        this.renderProfileLabel = this.renderProfileLabel.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
    }

    async componentDidMount() {
        this.setState({
            profileData: (await axios.get("/api/v1/profiles")).data,
            languageData: (await axios.get("/api/v1/languages")).data,
        });
        this.update();
    }

    async update() {
        const { dateRange } = this.state;
        const result = await axios.get("/api/v1/visitors/metrics", {
            params: {
                start: dateRange[0].toISOString(),
                end: dateRange[1].toISOString(),
            },
        });
        this.setState({
            profiles: result.data.profileMetrics,
            languages: result.data.languageMetrics,
            visitors: result.data.visitorMetrics,
        });
        this.forceUpdate();
    }

    renderProfileLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        const {t} = this.props;
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const key = this.state.profiles[index].profile;
        const label = v.titleCase(t(key));
        return <text x={x} y={y} fill="#4a4a4a" textAnchor={x < 300 ? "end" : "start"}>
            {`${label} (${(percent * 100).toFixed(0)}%)`}
        </text>;
    };

    renderLanguageLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const key = this.state.languages[index].language;
        const label = this.state.languageData.find((language) => language.key === key).name;
        return <text x={x} y={y} fill="#4a4a4a" textAnchor={x < 300 ? "end" : "start"}>
            {`${label} (${(percent * 100).toFixed(0)}%)`}
        </text>;
    };


    COLORS = ["#fe6a49", "#4a4a4a", "#eecf3a", "#007cba"];

    onDateRangeChange(dateRange) {
        this.setState({ dateRange }, () => {
            this.update();
        });
    }

    render() {
        const { profiles, languages, visitors, dateRange } = this.state;
        const { translation, t} = this.props;
        return (
            <>
                <Row gutter={16}>
                    <Col className="gutter-row" span={32}>
                        <Form.Item label="Date Range">
                            <RangePicker defaultValue={dateRange} onChange={this.onDateRangeChange}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Title level={3}>{v.titleCase(t("profile_plural"))}</Title>
                        {profiles.length &&
                        <PieChart width={600} height={400}>
                            <Pie dataKey="count" nameKey="profile" isAnimationActive={false} data={profiles} cx={300} cy={200} labelLine={false}
                                 label={this.renderProfileLabel}>
                                {
                                    profiles.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]}/>)
                                }
                            </Pie>
                        </PieChart> || <Empty/>}
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Title level={3}>{v.titleCase(t("language_plural"))}</Title>
                        {languages.length &&
                        <PieChart width={600} height={400}>
                            <Pie dataKey="count" nameKey="language" isAnimationActive={false} data={languages} cx={300} cy={200} labelLine={false}
                                 label={this.renderLanguageLabel}>
                                {
                                    languages.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]}/>)
                                }
                            </Pie>
                        </PieChart> || <Empty/>}
                    </Col>
                </Row>
                <Row>
                    <Col className="gutter-row" span={32}>
                        <Title level={3}>{v.titleCase(t("visitor_plural"))}</Title>
                        {visitors.length &&
                        <BarChart
                            width={800}
                            height={300}
                            data={visitors.map((visitor) => {
                                return {
                                    count: visitor.count,
                                    time: moment(visitor.time)
                                        .format("DD/MM"),
                                };
                            })}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="time"/>
                            <YAxis/>
                            <Tooltip/>
                            <Bar dataKey="count" fill={this.COLORS[0]}/>
                        </BarChart> || <Empty/>}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        languages: state.languages,
    };
};

const mapDispatchToProps = {
    syncLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(compose(Translatable)(Index));
