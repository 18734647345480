import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import classnames from "classnames";

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nodes: [],
            online: [],
            interval: null,
        };
        this.powerOn = this.powerOn.bind(this);
        this.powerOff = this.powerOff.bind(this);
        this.powerOnAll = this.powerOnAll.bind(this);
        this.powerOffAll = this.powerOffAll.bind(this);
        this.restart = this.restart.bind(this);
    }

    componentDidMount() {
        this.update();
        this.setState({
            interval: setInterval(async () => {
                await this.updateOnline();
            }, 1000),
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    update() {
        fetch("/api/v1/nodes")
            .then(response => response.json())
            .then(data => this.setState({nodes: data}));
    }

    async updateOnline() {
        const result = await axios.get("/api/v1/nodes/online");
        this.setState({online: result.data});
    }

    async powerOn(node) {
        await axios.post("/api/v1/nodes/" + node.id + "/power-on");
    }

    async powerOff(node) {
        await axios.post("/api/v1/nodes/" + node.id + "/power-off");
    }

    async powerOnAll() {
        await axios.post("/api/v1/nodes/power-on");
    }

    async powerOffAll() {
        await axios.post("/api/v1/nodes/power-off");
    }


    async restart(node) {
        await axios.post("/api/v1/nodes/" + node.id + "/reboot");
    }

    isOnline(node){
        const result = this.state.online.find((online) => online.host === node.host);
        return result && result.online;
    }

    render() {
        const {nodes} = this.state;
        return (
            <div>
                <div className="box">
                    <div className="buttons">
                        <button className="button is-success" onClick={this.powerOnAll}>
                                <span className="icon">
                                    <i className="fas fa-power-off"></i>
                                </span>
                            <span>Alles opstarten</span>
                        </button>
                        <button className="button is-danger" onClick={this.powerOffAll}>
                                <span className="icon">
                                    <i className="fas fa-moon"></i></span>
                            <span>Alles afsluiten</span>
                        </button>
                    </div>
                </div>
                <table className="table is-bordered is-fullwidth">
                    <thead>
                    <tr>
                        <th>Node</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {nodes.map(node =>
                        <tr key={node.id} className={classnames({"is-selected": node.active})}>
                            <td>{node.name}</td>
                            <td>
                                {this.isOnline(node) &&
                                <i className="fas fa-check-circle has-text-success"></i> || <i
                                    className="fas fa-times-circle has-text-danger"></i>}
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <button className="button is-small"
                                                onClick={() => this.powerOn(node)}>
                                            <i className="fas fa-power-off"></i>
                                        </button>
                                    </p>
                                    <p className="control">
                                        <button className="button is-small has-text-dark"
                                                onClick={() => this.powerOff(node)}>
                                            <i className="fas fa-moon"></i>
                                        </button>
                                    </p>
                                    <p className="control">
                                        <button className="button is-small has-text-dark"
                                                onClick={() => this.restart(node)}>
                                            <i className="fas fa-undo"></i>
                                        </button>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}
