import axios from "axios";
import _ from "lodash";
import Actions from "~app/constants/actions";

const syncApplicationsAction = _.throttle(async dispatch => {
    const result = await axios.get("/api/v1/applications");
    dispatch({
        type: Actions.STORE_APPLICATIONS,
        payload: result.data,
    });
}, 1000);

const syncShowcasesAction = _.throttle(async dispatch => {
    const result = await axios.get("/api/v1/showcases");
    dispatch({
        type: Actions.STORE_SHOWCASES,
        payload: result.data,
    });
}, 1000);

const syncLanguagesAction = _.throttle(async dispatch => {
    const result = await axios.get("/api/v1/languages");
    dispatch({
        type: Actions.STORE_LANGUAGES,
        payload: result.data,
    });
}, 1000);

const changeCurrentLanguageAction = _.debounce(async (dispatch, language) => {
    dispatch({
        type: Actions.CHANGE_CURRENT_LANGUAGE,
        payload: language,
    });
}, 100);

export const syncApplications = (...args) => dispatch => syncApplicationsAction(dispatch, ...args);
export const syncShowcases = (...args) => dispatch => syncShowcasesAction(dispatch, ...args);
export const syncLanguages = (...args) => dispatch => syncLanguagesAction(dispatch, ...args);
export const changeCurrentLanguage = (...args) => dispatch => changeCurrentLanguageAction(dispatch, ...args);
export default {
    syncApplications,
    syncShowcases,
    syncLanguages,
    changeCurrentLanguage,
};
