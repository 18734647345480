import { Table, Transfer } from "antd";
import React from "react";
import Sortable from "~app/hoc/sortable";

const SortableTable = Sortable(Table);

const SortableTableTransfer = ({ leftColumns, rightColumns, models, onSort, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={false}>
        {({
            direction,
            filteredItems,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === "left" ? leftColumns : rightColumns;

            const rowSelection = {
                getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (direction === "right" ?
                    <SortableTable
                        pagination={false}
                        models={models}
                        onSort={onSort}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        style={{ pointerEvents: listDisabled ? "none" : null }}
                    /> : <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        size="small"
                        style={{ pointerEvents: listDisabled ? "none" : null }}
                    />
            );
        }}
    </Transfer>
);

export default SortableTableTransfer;
