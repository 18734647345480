import React, { Component } from "react";
import axios from "axios";
import { Button, Card, Checkbox, Col, DatePicker, Form, Icon, Input, Row, Select, Tabs, Upload } from "antd";
import TextEditor from "~app/components/text-editor";
import TitleEditor from "~app/components/title-editor";
import "react-quill/dist/quill.snow.css";
import ShowcaseObject from "~app/models/object";
import v from "voca";
import { connect } from "react-redux";
import { syncShowcases } from "~app/actions";
import Translatable from "~app/hoc/translatable";
import Imagable from "~app/hoc/imagable";
import WithMediaLinks from "~app/hoc/medialinks";
import compose from "lodash/fp/compose";
import moment from "moment";
import update from "immutability-helper";

const { TabPane } = Tabs;
const { Option } = Select;

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            object: null,
        };
        this.save = this.save.bind(this);
        this.setMediaLinks = this.setMediaLinks.bind(this);
    }

    async componentDidMount() {
        this.props.syncShowcases();
        this.setState({
            object: await this.getObject(),
        });
    }

    async getObject() {
        let object = this.props.location.state && this.props.location.state.object || null;
        if (object !== null) {
            return new ShowcaseObject(object);
        }
        const objectId = this.props.match.params.id;
        if (objectId) {
            const result = await axios.get(`/api/v1/showcases/objects/${objectId}`);
            return new ShowcaseObject(result.data);
        }
        object = new ShowcaseObject();
        return object;
    }

    async save() {
        const { form, goBack } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { object } = this.state;
                object.internal = values.internal;
                const data = {
                    showcaseObject: object,
                };
                await axios.post(object.id ? `/api/v1/showcases/objects/${object.id}` : "/api/v1/showcases/objects", data);
                goBack();
            }
        });
    }

    setMediaLinks(mediaLinks) {
        const newState = update(this.state, {
            object: {
                mediaLinks:
                    { $set: mediaLinks },
            },
        });
        this.setState(newState);
    }

    render() {
        const { t, showcases, languages, currentTranslation, goBack, form, currentLanguage } = this.props;
        const onChange = this.props.onChange.bind(this);
        const getFilesFromMediaLinks = this.props.getFilesFromMediaLinks.bind(this);
        const syncFilesToMediaLinks = this.props.syncFilesToMediaLinks.bind(this);
        const { object } = this.state;
        const { getFieldDecorator } = form;
        return (
            <>
                {object && (
                    <Form>
                        <h1 className="title">{object.internal} {object.published && (<Icon type="eye"/> || <Icon type="eye-invisible"/>)}</h1>
                        <Card title={v.titleCase(t("property_plural"))}>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("name_internal"))}>
                                        {getFieldDecorator("internal", {
                                            initialValue: object.internal,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: v.capitalize(t("error_required")),
                                                },
                                            ],
                                        })(
                                            <Input placeholder={v.titleCase(t("name_internal"))}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item>
                                        <Checkbox defaultChecked={object.isTop} onChange={e => onChange("object", "isTop",e.target.checked)}>{v.capitalize(t("object_top"))}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item label={v.titleCase(t("showcase"))}>
                                        <Select defaultValue={object.showcaseId} style={{ width: "100%" }} onChange={value => onChange("object", "showcaseId", value)}>
                                            <Option title={v.capitalize(t("showcase_not_selected"))} key={null} value={null}>{v.capitalize(t("showcase_not_selected"))} </Option>
                                            {showcases.map(showcase => (
                                                <Option title={showcase.internal} key={showcase.id} value={showcase.id}>{showcase.internal}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-between" gutter={[20, 20]}>
                                <Col span={12}>
                                    <Form.Item label={v.capitalize(t("published_from"))}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTime={false}
                                            format="DD/MM/YYYY"
                                            placeholder={v.capitalize(t("published_from"))}
                                            allowClear={true}
                                            defaultValue={object.startPublishingDate ? moment(object.startPublishingDate) : null}
                                            onChange={date => onChange("object", "startPublishingDate", date ? date.toISOString() : null)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={v.capitalize(t("published_until"))}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            showTime={false}
                                            format="DD/MM/YYYY"
                                            placeholder={v.capitalize(t("published_until"))}
                                            allowClear={true}
                                            defaultValue={object.endPublishingDate ? moment(object.endPublishingDate) : null}
                                            onChange={date => onChange("object", "endPublishingDate", date ? date.toISOString() : null)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Tabs defaultActiveKey={currentLanguage} animated={false} className="mt20">
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Card title={v.titleCase(t("content"))}>
                                        <Form.Item label={v.titleCase(t("title"))}>
                                            <TitleEditor placeholder={v.titleCase(t("title"))} defaultValue={object.title[language.key]}
                                                   onChange={value => onChange("object", "title", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("title_extended"))}>
                                            <TitleEditor placeholder={v.titleCase(t("title_extended"))} defaultValue={object.extendedTitle[language.key]}
                                                   onChange={value => onChange("object", "extendedTitle", value, language.key)}/>
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description_extra"))} extra={v.capitalize(t("description_extra_info"))}>
                                            {<TextEditor
                                                placeholder={v.titleCase(t("description_extra"))}
                                                defaultValue={object.descriptions.extra[language.key]}
                                                onChange={value => onChange("object", ["descriptions", "extra"], value, language.key)}
                                            />}
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description_explorer"))} extra={v.capitalize(t("description_explorer_info"))}>
                                            {<TextEditor
                                                placeholder={v.titleCase(t("description_explorer"))}
                                                defaultValue={object.descriptions.explorer[language.key]}
                                                onChange={value => onChange("object", ["descriptions", "explorer"], value, language.key)}
                                            />}
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description_academic"))} extra={v.capitalize(t("description_academic_info"))}>
                                            {<TextEditor
                                                placeholder={v.titleCase(t("description_academic"))}
                                                defaultValue={object.descriptions.academic[language.key]}
                                                onChange={value => onChange("object", ["descriptions", "academic"], value, language.key)}
                                            />}
                                        </Form.Item>
                                    </Card>
                                </TabPane>
                            ))}
                        </Tabs>
                        <Card title={v.titleCase(t("medium_plural"))} className="mt20">
                            <Form.Item label={v.titleCase(t("preview"))} extra={v.capitalize(t("preview_extra"))}>
                                <Upload listType="picture-card" action="/api/v1/media/upload" onChange={e => this.setMediaLinks(syncFilesToMediaLinks(object.mediaLinks, e.fileList, "preview"))}
                                        defaultFileList={getFilesFromMediaLinks(object.mediaLinks, "preview")}>
                                    {getFilesFromMediaLinks(
                                        object.mediaLinks,
                                        "preview",
                                    ).length < 2 &&
                                    <Button>
                                        <Icon type="plus"/> {v.titleCase(t("upload"))}
                                    </Button>}
                                </Upload>
                            </Form.Item>
                            <Form.Item label={v.titleCase(t("content"))} extra={v.capitalize(t("content_extra"))}>
                                <Upload listType="picture-card" action="/api/v1/media/upload" onChange={e => this.setMediaLinks(syncFilesToMediaLinks(object.mediaLinks, e.fileList, "content"))}
                                        defaultFileList={getFilesFromMediaLinks(object.mediaLinks, "content")}>
                                    <Button>
                                        <Icon type="plus"/> {v.titleCase(t("upload"))}
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <div className="field is-grouped is-pulled-right mt20">
                                <div className="control">
                                    <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                                </div>
                                <div className="control">
                                    <button className="button" onClick={goBack}>{v.titleCase(t("cancel"))}</button>
                                </div>
                            </div>
                        </Card>
                    </Form>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        showcases: state.showcases,
    };
};

export default compose(WithMediaLinks, Imagable, Translatable, Form.create({ name: "edit" }), connect(mapStateToProps, {
    syncShowcases,
}))(Edit);
