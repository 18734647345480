import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";
import v from "voca";
import Translatable from "~app/hoc/translatable";
import Object from "~app/models/object";
import { Button, Icon, Input, message, Popconfirm, Table } from "antd";
import _ from "lodash";
import { withRouter } from "react-router-dom";

const { Column } = Table;
const { Search } = Input;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objects: [],
        };
        this.search = _.debounce(this.search.bind(this), 200);
    }

    async componentDidMount() {
        this.setState({
            objects: await this.getObjects(),
        });
    }

     async getObjects(search) {
        const { history } = this.props;
        try {
            const params = {};
            params.search = search;
            const result = await axios.get("/api/v1/showcases/objects", { params });
            return result.data.map(r => new Object(r));
        } catch (err) {
            message.error("Network Error");
            return [];
        }
    }


    async deleteObject(object) {
        await axios.delete("/api/v1/showcases/objects/" + object.id);
        this.setState({
            objects: await this.getObjects(),
        });
    }

    async search(search) {
        this.setState({
            objects: await this.getObjects(search),
        });
    }

    render() {
        const { t, currentTranslation } = this.props;
        const { objects } = this.state;
        return (
            <>
                <Search placeholder={v.capitalize(t("object_search_plural"))} onSearch={this.search} onChange={e => this.search(e.target.value)} allowClear/>
                <Table dataSource={objects} rowKey={object => object.id} showSearch={true} className="mt20">
                    <Column key="internal" title={v.titleCase(t("name_internal"))} render={object => object.internal || "-"}/>
                    <Column key="title" title={v.titleCase(t("title"))} render={object => <div dangerouslySetInnerHTML={{__html:currentTranslation(object.title)}}></div>}/>
                    <Column key="published" title={v.titleCase(t("published"))} render={object => object.published && <Icon type="eye"/> || <Icon type="eye-invisible"/>}/>
                    <Column key="showcase" title={v.titleCase(t("showcase"))} render={object => <>{object.showcase ? object.showcase.internal : "-"}</>}/>
                    <Column key="top" title={v.titleCase(t("object_top"))} render={object => object.isTop && <Icon type="star"/>}/>
                    <Column key="actions" className="buttons-group" title={v.titleCase(t("action_plural"))} render={object => <><Link
                        to={{
                            pathname: `/objects/${object.id}`,
                            state: {
                                object,
                            },
                        }}>
                        <Button icon="edit"/>
                    </Link><Popconfirm title={v.capitalize(t("object_delete"))} icon={<Icon type="delete" style={{ color: "red" }}/>} onConfirm={() => this.deleteObject(object)}
                    ><Button icon="delete"/>
                    </Popconfirm></>}/>
                </Table>
                <Link
                    to={{
                        pathname: "/objects/create",
                    }}
                >
                    <Button icon="plus">{v.titleCase(t("object_new"))} </Button>
                </Link>
            </>
        );
    }
}

export default withRouter(Translatable(Index));
