import React, { Component } from "react";
import axios from "axios";
import Showcase from "~app/models/showcase";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Card, Form, Icon, Input, Select, Tabs } from "antd";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import v from "voca";
import { withRouter } from "react-router";
import compose from "lodash/fp/compose";
import WithMediaLinks from "~app/hoc/medialinks";
import Imagable from "~app/hoc/imagable";
import Translatable from "~app/hoc/translatable";
import SortableTableTransfer from "~app/components/sortabletabletransfer";
import TextEditor from "~app/components/text-editor";

const { TabPane } = Tabs;
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const identifiers = ["A", "B", "C", "D", "E", "F"];

const leftObjectColumns = function leftObjectColumns(t) {
    return [
        {
            key: "internal",
            title: v.titleCase(t("name_internal")),
            render: object => object.internal || "-",
        },
        {
            key: "showcase",
            title: v.titleCase(t("showcase")),
            render: object => {
                return object.showcase && object.showcase.internal || "-";
            },
        },
    ];
};

const rightObjectColumns = function rightObjectColumns(t) {
    return [
        {
            key: "internal",
            title: v.titleCase(t("name_internal")),
            render: object => <Link to={"/objects/" + object.id}>{object.internal || "-"}</Link>,
        },
        {
            key: "published",
            title: v.titleCase(t("published")),
            render: object => {
                return object.published && <Icon type="eye"/> || <Icon type="eye-invisible"/>;
            },
        },
        {
            key: "top",
            title: v.titleCase(t("object_top")),
            render: object => {
                return object.isTop && <Icon type="star"/>;
            },
        },
    ];
};

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showcase: null,
            objects: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.setChange = this.setChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setPublished = this.setPublished.bind(this);
        this.handleInternal = this.handleInternal.bind(this);
        this.onObjectsSort = this.onObjectsSort.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    onChange(ids) {
        const { showcase } = this.state;
        showcase.objectIds = ids;
        this.setState({ showcase });
    }

    handleInternal(internal) {
        const { showcase } = this.state;
        showcase.internal = internal;
        this.setState({ showcase });
    }


    async componentDidMount() {
        this.setState({
            showcase: await this.getShowcase(),
            objects: await this.getObjects(),
        });
    }

    handleChange(event) {
        const { target } = event;
        const { showcase } = this.state;
        showcase[target.name] = target.value;
        this.setState({
            showcase,
        });
    }

    async getShowcase() {
        let showcase = this.props.location.state && this.props.location.state.showcase || null;
        if (showcase !== null) {
            return new Showcase(showcase);
        }
        const showcaseId = this.props.match.params.id;
        if (showcaseId) {
            const result = await axios.get(`/api/v1/showcases/${showcaseId}`);
            return new Showcase(result.data);
        }
        showcase = new Showcase();
        return showcase;
    }

    async getObjects() {
        const result = await axios.get("/api/v1/showcases/objects");
        return result.data;
    }

    async save() {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (!err) {
                const { showcase } = this.state;
                showcase.internal = values.internal;
                showcase.identifier = values.identifier;
                showcase.name = values.name;
                showcase.description = values.description;
                const data = {
                    showcase,
                };
                await axios.post(showcase.id ? `/api/v1/showcases/${showcase.id}` : "/api/v1/showcases", data);
                this.goBack();
            }
        });
    }

    setPublished(value) {
        const { showcase } = this.state;
        showcase.published = value;
        this.setState({ showcase });
    }

    setChange(val) {
        const { showcase } = this.state;
        showcase.name = val;
        this.setState({ showcase });
    }

    async onObjectsSort(objectIds) {
        for (const objectId of objectIds) {
            const data = {
                object: {
                    position: objectIds.indexOf(objectId),
                },
            };
           await axios.patch(`/api/v1/showcases/objects/${objectId}`, data);
        }
        const { showcase } = this.state;
        const result = await axios.get(`/api/v1/showcases/${showcase.id}`);
        this.setState({ showcase: new Showcase(result.data) });
    }

    render() {
        const { t, form, languages, currentLanguage, translation } = this.props;
        const { showcase, objects } = this.state;
        const { getFieldDecorator } = form;
        return (
            showcase
            && (
                <div>
                    <Form {...formItemLayout}>
                        <h1 className="title">{showcase.internal}</h1>
                        <Card title={v.titleCase(t("property_plural"))}>
                            <Form.Item label={v.titleCase(t("name_internal"))}>
                                {getFieldDecorator("internal", {
                                    initialValue: showcase.internal,
                                    rules: [
                                        {
                                            required: true,
                                            message: v.capitalize(t("error_required")),
                                        },
                                    ],
                                })(
                                    <Input placeholder={v.titleCase(t("name_internal"))}/>
                                )}
                            </Form.Item>
                            <Form.Item label={v.titleCase(t("identifier"))}>
                                {getFieldDecorator("identifier", {
                                    initialValue: showcase.identifier,
                                })(
                                    <Select placeholder={v.titleCase(t("identifier"))}>
                                        <Option key={null} value={null}>No Identifier</Option>
                                        {identifiers.map(identifier => <Option key={identifier} value={identifier}>{identifier}</Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Card>
                        <Tabs defaultActiveKey={currentLanguage} animated={false} className="mt20">
                            {languages.map(language => (
                                <TabPane tab={language.name} key={language.key}>
                                    <Card title={v.titleCase(t("content"))}>
                                        <Form.Item label={v.titleCase(t("title"))}>
                                            {getFieldDecorator("name[" + language.key + "]", {
                                                initialValue: showcase.name[language.key],
                                            })(
                                                <Input placeholder={v.titleCase(t("name"))}/>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={v.titleCase(t("description"))} extra={v.capitalize(t("description_info"))}>
                                            {getFieldDecorator("description[" + language.key + "]", {
                                                initialValue: showcase.description[language.key] || null,
                                            })(<TextEditor
                                                placeholder={v.titleCase(t("description"))}
                                            />)}
                                        </Form.Item>
                                    </Card>
                                </TabPane>
                            ))}
                        </Tabs>
                        <Card title={v.titleCase(t("object_plural"))} className="mt20">
                            <Form.Item>
                                {showcase.objects &&
                                <SortableTableTransfer
                                    onSort={this.onObjectsSort}
                                    models={showcase.objects}
                                    titles={[v.titleCase(t("object_unselected_plural")), v.titleCase(t("object_selected_plural"))]}
                                    rowKey={object => object.id}
                                    dataSource={objects}
                                    targetKeys={showcase.objectIds}
                                    onChange={this.onChange}
                                    leftColumns={leftObjectColumns(t)}
                                    rightColumns={rightObjectColumns(t)}
                                    showSearch={true}
                                    filterOption={(input, option) => {
                                        return translation(option.title)
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) || option.internal && option.internal.toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                />
                                }
                            </Form.Item>
                        </Card>
                        <div className="field is-grouped is-pulled-right mt20">
                            <div className="control">
                                <button className="button is-success" onClick={this.save}>{v.titleCase(t("save"))}</button>
                            </div>
                            <div className="control">
                                <button className="button" onClick={this.goBack}>{v.titleCase(t("cancel"))}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            )
        );
    }
}

export default compose(WithMediaLinks, Imagable, Translatable, withRouter, Form.create({ name: "edit" }))(Edit);
