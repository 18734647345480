import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nl from "./nl.json";
import fr from "./fr.json";

const resources = {
    nl: {
        translation: nl,
    },
    fr: {
        translation: fr,
    },
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "fr-BE",
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
