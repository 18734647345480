import React, { Component } from "react";

const getImage = function (uri) {
    return `/api/v1/media/download/${uri}`;
};

const resizeImage = function (uri, width, height) {
    return `/cache/signature/resize:fill:${width}:${height}:0/plain/http://app/api/v1/media/download/${encodeURI(uri)}@png`;
};

function Imagable(WrappedComponent) {
    return class extends Component {
        render() {
            return <WrappedComponent getImage={getImage} resizeImage={resizeImage} {...this.props} />;
        }
    };
}

export default Imagable;
